import React, { useState } from "react";
import  styled, { keyframes } from "styled-components";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import Top from "../../components/Top"
import TopAnswer from "../../components/TopAnswer"
import BottomShare from "../../components/BottomShare"
import InputForm from "../../components/InputForm"
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import moment from 'moment';
import 'moment/locale/ru';

import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade'
import Rotate from 'react-reveal/Rotate';
import Flip from 'react-reveal/Flip';

import Logo from '../../assets/logo'
import {lay} from '../../constants/Layout'
import { Height } from "@material-ui/icons";


import {Context} from "../../functions/context"

import DietForm from "../../components/DietForm"

export default function Diets(props) {

  
  
  return (
    <Context.Consumer>
    {({theme, 
      toggleTheme,
      diets,
      setDiets,}) => (
    <>
      <Top
        toggleTheme={()=>props.toggleTheme()}
      />
      
      <Container
      //className={classes.root}
        style={{
          flexWrap: (lay.window.width>700) ? 'wrap':'nowrap',
          flexDirection: (lay.window.width>700) ? 'row':'column',
          alignItems: (lay.window.width>700) ? 'flex-start':'centre',
          justifyContent:'flex-start',
          paddingTop:  80,
          minHeight: lay.window.height-80,
          transition: '1s',
          
          backgroundColor: (theme=='light')?"#fff":"#000",
        }}
      >
          <DietForm
            type='new'
            title='Добавить новую диету'
            item={{id:0}}
            onSave={()=>{}}
            onDelete={()=>{}}
            endAdornment=''
            api='diets.php'
          />
          {diets.map((item, index)=>
            <DietForm
              key={index}
              type='edit'
              title={'редактировать диету '+item.name}
              item={item}
              onSave={()=>{}}
              onDelete={()=>{}}
              endAdornment=''
              api='diets.php'
            />
          )}
  
      </Container>
    </>
    )}
    </Context.Consumer>
  );
}


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  transition: '1s'
  },
  margin16: {
      margin: theme.spacing(2),
      marginBottom: theme.spacing(1),
      width: 300,
      transition: '1s',
      color: theme.palette.text.primary
  },
  button: {
   transition: '1s'
  }
  
}));


const Container = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: '1s'
`;