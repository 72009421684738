import React, { Component } from "react";
import styled, { css } from "styled-components";
import axi from '../functions/axiosf';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';

import {Context} from "../functions/context"

export default function DietForm(props) {
  const classes = useStyles();
  const [name, setName] = useState(props.item.name)
  const [proteins, setProteins] = useState(props.item.proteins)
  const [fats, setFats] = useState(props.item.fats)
  const [carbs, setCarbs] = useState(props.item.carbs)
  const [caloric, setCaloric] = useState(props.item.caloric)

  const [edited, setEdited] = useState(false)
  const context = useContext(Context);
  const onSave = () =>{
//в метод пробразсываем параметр props.type
    context.onSave(props.api, props.type, 
                    {id: props.item.id, 
                      name: name,
                      proteins: proteins,
                      fats: fats,
                      carbs: carbs,
                      caloric: caloric
                    })
    setEdited(false)
  }
  const onDelete = () => {
    context.onSave(props.api, 'delete', {id: props.item.id, name: name})
    setEdited(false)
  }
    return (
      <Container
        style={{backgroundColor: (context.theme==='light') ? '#dde9':'#4459',
          borderColor: edited? '#d1d':'#778'}}
      >
      <div className={classes.root}>
        <p
          style={{
            color: (context.theme==='light') ? '#222':'#eef',
            textAlign: 'center',
            padding: 5}}
        >{props.title}</p>
        <TextField
            className={classes.margin16}
            onChange={(e)=>{
              setName(e.target.value)
              setEdited(true)}}
            value = {name}
            type="text"
            id="outlined-helperText"
            label={'наименование диеты'}
            helperText={props.helperText}
            variant="outlined"
            //autoFocus={props.autoFocus}
            focused={props.focused}
            InputProps={{
              endAdornment: <InputAdornment position="end">{props.endAdornment}</InputAdornment>,
            }}
          />
         <TextField
            className={classes.margin16}
            onChange={(e)=>{
              setProteins(e.target.value)
              setEdited(true)}}
            value = {proteins}
            type="number"
            id="outlined-helperText"
            label={'белки'}
            helperText={props.helperText}
            variant="outlined"
            //autoFocus={props.autoFocus}
            focused={props.focused}
            InputProps={{
              endAdornment: <InputAdornment position="end">{'грамм'}</InputAdornment>,
            }}
          /><TextField
            className={classes.margin16}
            onChange={(e)=>{
              setFats(e.target.value)
              setEdited(true)}}
            value = {fats}
            type="number"
            id="outlined-helperText"
            label={'жиры'}
            helperText={props.helperText}
            variant="outlined"
            //autoFocus={props.autoFocus}
            focused={props.focused}
            InputProps={{
              endAdornment: <InputAdornment position="end">{'грамм'}</InputAdornment>,
            }}
          /><TextField
            className={classes.margin16}
            onChange={(e)=>{
              setCarbs(e.target.value)
              setEdited(true)}}
            value = {carbs}
            type="number"
            id="outlined-helperText"
            label={'углеводы'}
            helperText={props.helperText}
            variant="outlined"
            //autoFocus={props.autoFocus}
            focused={props.focused}
            InputProps={{
              endAdornment: <InputAdornment position="end">{'грамм'}</InputAdornment>,
            }}
          /> 
        <TextField
            className={classes.margin16}
            onChange={(e)=>{
              setCaloric(e.target.value)
              setEdited(true)}}
            value = {caloric}
            type="number"
            id="outlined-helperText"
            label={'энергетическая ценность'}
            helperText={props.helperText}
            variant="outlined"
            //autoFocus={props.autoFocus}
            focused={props.focused}
            InputProps={{
              endAdornment: <InputAdornment position="end">{'кКал'}</InputAdornment>,
            }}
          />
          

          {props.type==='new'&&edited&&
            <Button 
              color="primary"
              variant="contained"
              onClick={()=>{
                setEdited(false)
                onSave()}}>
              добавить
            </Button>
          }
          {props.type==='edit'&&edited&&
            <Button 
              color="primary"
              variant="contained"
              onClick={()=>{
                setEdited(false)
                onSave()}}>
              изменить
            </Button>
          }
          {props.type==='edit'&&
            <Button 
              color="secondary"
              variant="contained"
              onClick={()=>onDelete()}>
              удалить
            </Button>
          }

      </div>
      </Container>)
}


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  transition: '1s'
  },
  margin16: {
      margin: theme.spacing(2),
      marginBottom: theme.spacing(1),
      width: 260,
      transition: '1s'
  },
  button: {
   transition: '1s'
  }
  
}));
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 300px;
  margin: 5px;
  overflow: hidden;
  transition: '1s';
  border: 2px solid #778;
  border-radius: 8px;
`;