import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import axi from '../functions/axiosf';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { ru } from 'date-fns/locale';

import moment from 'moment';
import 'moment/locale/ru';

import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Rotate from 'react-reveal/Rotate';
import Flip from 'react-reveal/Flip';

import {Context} from '../functions/context';

export default function PatientForm(props) {

  const context = useContext(Context);
  const classes = useStyles();
  const [name, setName] = useState(props.item.name);
  const [contraindications, setСontraindications] = useState(props.item.contraindications);
  const [dateTime, setDateTime] = useState(props.item?.dateTime ? +props.item?.dateTime : +new Date());
  const [number, setNumber] = useState(props.item.number);
  const [diet, setDiet] = useState(props.item.diet);
  const [to, setTo] = useState();
  
  const [edited, setEdited] = useState(false);

  const onTransfer = () =>{
    context.axiDietRoom('transfer', {
      item: props.item, 
      name: name, 
      diet: diet, 
      dateTime: dateTime, 
      to: to, 
      contraindications: contraindications
    });
    setEdited(false);
    setName(props.item.name);
    setDiet(props.item.diet);
  };

  const onSave = () =>{
    context.axiDietRoom('edit', {
      item: props.item, 
      name: name, 
      diet: diet, 
      dateTime: dateTime, 
      contraindications: contraindications
    });
    setEdited(false);
  };
  const onDelete = () => {
    context.axiDietRoom('clear', props.item);
    setDiet({id: 0, name: null});
    setName('');
  };
  //console.log(props)
  return (
    <Container
      style={{
        backgroundColor: (context.theme==='light') ? '#dde9':'#4459',
        borderColor: edited? '#d1d':'#778'
      }}
    >
      <div className={classes.root}>
        <p
          style={{
            color: (context.theme==='light') ? '#222':'#eef',
            textAlign: 'center',
            padding: 5}}
        >{'редактировать пациента в палате '}<b>{number}</b></p>
        <TextField
          className={classes.margin16}
          onChange={(e)=>{
            setName(e.target.value);
            setEdited(true);
          }}
          value = {name}
          type="text"
          id="outlined-helperText"
          label={props.label}
          helperText={props.helperText}
          variant="filled"
          placeholder="имя пациента"
          //autoFocus={props.autoFocus}
          focused={props.focused}
          InputProps={{
            endAdornment: <InputAdornment position="end">{props.endAdornment}</InputAdornment>,
          }}
        />
          
        <Divider 
          className={classes.margin16}
          style={{marginTop: 0}}/>

        <p
          style={{
            color: (context.theme==='light') ? '#222':'#eef',
            textAlign: 'center',
            padding: 5}}
        >диета</p>
        {context.diets&&
          <ButtonGroup 
            className={classes.margin16} 
            size="large" color="primary" 
            orientation="vertical"
            aria-label="vertical large primary button group">
            {context.diets.map((item, index)=>{
              return(
                <Button 
                  key={index}
                  className={classes.button}
                  variant={(diet.name===item.name)?'contained':'outlined'}
                  onClick={()=>{
                    setDiet(item);
                    setEdited(true);
                  }}
                >{item.name}</Button>
              );
            })}
          </ButtonGroup>
        }
        <TextField
          className={classes.margin16}
          onChange={(e)=>{
            setСontraindications(e.target.value);
            setEdited(true);
          }}
          value = {contraindications}
          type="text"
          id="outlined-helperText"
          label="аллергия и противопоказания"
          variant="filled"
          focused={props.focused}
        />
        <LocalizationProvider 
          dateAdapter={AdapterDateFns} 
          locale={ru}
        >
          <DateTimePicker
            renderInput={(props) => <TextField {...props} variant="filled"/>}
            label="Дата и время выписки"
            value={dateTime}
            onChange={(value) => {
              setDateTime(new Date(value).getTime());
              setEdited(true);
            }}
          />
        </LocalizationProvider>

        {edited&&
            <Rotate bottom cascade>
              <Button 
                color="primary"
                variant="contained"
                onClick={()=>onSave()}>
                изменить
              </Button>
            </Rotate>
        }
        {name&&
          <HorizontDiv>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">{'=> в палату'}</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={to}
                onChange={(e)=>{
                  setTo(e.target.value);
                }}
                label="=> в палату"
              >
                {context.rooms.map((item, index)=>{
                  if(item.name===''){
                    return(
                      <MenuItem MenuItem key={index} value={item.number}>{item.number}</MenuItem>
                    );
                  }else{
                    return null;
                  }
                }
                )
                }
              </Select>
            </FormControl>
          
            <Rotate bottom cascade>
              <Button 
                color="primary"
                variant="contained"
                onClick={
                  ()=>onTransfer ()
                }>
                  перевести
              </Button>
            </Rotate>
          </HorizontDiv>
        }
        {props.item.name&&props.item.diet&&
        <Button 
          color="secondary"
          variant="contained"
          onClick={()=>onDelete()}>
              выписать
        </Button>
        }
      </div>
    </Container>
  );
}


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
    transition: '1s'
  },
  margin16: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: 260,
    transition: '1s'
  },
  button: {
    transition: '1s'
  },
  formControl: {
    minWidth: 130,
  },
  
}));
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 300px;
  margin: 5px;
  overflow: hidden;
  transition: '1s';
  border: 2px solid #778;
  border-radius: 8px;
`;
const HorizontDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 260px;
  height: 80px;
  overflow: hidden;
  transition: '1s';
`;