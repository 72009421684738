/* eslint-disable consistent-return */
import React, { useContext, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Top from '../../../components/Top';

import moment from 'moment';
import 'moment/locale/ru';

import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Rotate from 'react-reveal/Rotate';
import Flip from 'react-reveal/Flip';

import {lay} from '../../../constants/Layout';
import { Height } from '@material-ui/icons';

import {Context} from '../../../functions/context';
import BarmaidModal from './BarmaidModal';


export default function MainBarmaid(props) {

  const classes = useStyles();
  const [selectedRoom, setRoom] = useState(null);
  const [edited, setEdited] = useState(0);
  const [orders, setOrders] = useState([]);
  const [currentAdditionalMealsComments, setCurrentAdditionalMealsComments] = useState([]);

  const context = useContext(Context);

  const today = moment(+new Date()).format('ll');
  const nowTime = moment(+new Date()).format('kk:mm');
  const nowH = nowTime[0]+nowTime[1];
  const nowM = nowTime[3]+nowTime[4];
  const nowMinutes = +nowH*60 + +nowM;
  console.log(nowTime+': '+nowH+', '+nowM+' в минутах '+nowMinutes);

  const onSave=(ordersToSave)=>{
    context.axiSaveOrders({
      item: selectedRoom, 
      orders: ordersToSave, 
      today: context.updateDay
    });
    setEdited(0);
    setRoom(null);
    let cleenArr = [];
    setOrders(cleenArr);
  };

  const handleSelectRoom = (room) => {
    if(context.orders.length > 0){
      let localOrders = [];
      context.orders.map((orderItem, orderIndex)=>{
        if (orderItem.roomName === room.number){
          localOrders = [...localOrders, orderItem];
        }
      });
      setOrders(localOrders);
      let newCurrentAdditionalMealsComments = [];
      context.additionalMealsComments.map((commentObjItem, commentObjIndex)=>{
        if (commentObjItem.room === room.number){
          newCurrentAdditionalMealsComments = [...newCurrentAdditionalMealsComments, commentObjItem];
        }
      });
      setCurrentAdditionalMealsComments(newCurrentAdditionalMealsComments);
    }
    setRoom(room);
  };

  useEffect(()=>{
    let localOrders = [];
    setOrders(localOrders);
  },[context.updateDay]);

  return (
    
    <>
      <Top/>
      
      {selectedRoom&&
        <BarmaidModal
          onSave={onSave}
          onSetRoom={setRoom}
          onSetOrders={setOrders}
          selectedRoom={selectedRoom}
          additionalMealsComments={currentAdditionalMealsComments}
          orders={orders}
        />
      }


      <Container className={classes.container} >
        {context.rooms.map((item, index) => {
          let completedOrder = false;
          if (item.name!==''){
            context.orders.map((itemOrder,indexOrder)=>{
              if(itemOrder.roomName===item.number){
                completedOrder=true;
              }
            });
            return(
              <Room
                key={index+'_'+context.updateDay}
                onClick={()=>{
                  handleSelectRoom(item);
                }}
                style={{
                  backgroundColor: completedOrder?'#99cc3399':((context.theme==='light') ? '#dde9':'#4459'),
                }}
              >
                <p className={classes.roomLabelRow}>
                  {'палата № '+item.number}
                </p>
                {item.name!==''?
                  <p className={classes.roomLabelRow}>
                    {'пациент '+item.name}</p>
                  :
                  <p className={classes.roomLabelRow}>
                    {'пустая'}</p>
                }
                {item.name!==''?
                  <p className={classes.roomLabelRow}>
                    {'диета: '+item.diet.name}</p>
                  :
                  <></>
                }
              </Room>
            );
          }
        }
        )}
      </Container>
    </>
  );
}


const useStyles = makeStyles((theme) => ({
  container: {
    flexWrap: 'wrap',
    flexDirection:  'row',
    alignItems: 'flex-start',
    justifyContent:'flex-start',
    paddingTop:  80,
    height: lay.window.height-80,
    transition: '1s',        
    backgroundColor: theme.palette.backgroundColor,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
    transition: '1s'
  },
  margin16: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: 260,
    transition: '1s',
    color: theme.palette.text.primary
  },
  button: {
    transition: '1s'
  },
  roomLabelRow:{ 
    color: theme.palette.text.primary,
    textAlign: 'center',
    padding: 5
  }  
}));

const Container = styled.div`
  display: flex;
  flex: 0 1 auto;  
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: '1s'
`;

const Room = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  
  border: 2px solid #778;
  border-radius: 8px;
  margin: 4px;
  cursor: pointer;
  width: 160px;
  height: 160px;
  overflow-y: hidden;
  overflow-x: hidden;
  transition: '1s'
`;
