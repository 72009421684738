import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import axi from '../functions/axiosf';

import IoniconsIcon from 'react-native-vector-icons/dist/Ionicons';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      visibilityLogInBlackOut: 'hidden',
      hoverColor: '',
      selectedColor: 'NewGoods',

    };
  }

  componentDidMount() {
    //console.log(this.props)
  }
 

  render(){
    let w = this.state.open ? 0 : 180;
    return (
      <div>
        <div className="burgerButton"
          onClick={()=>{
            this.setState({open: !this.state.open});
            this.props.menuOpenStatusToggle(w);
          }}
        >
          <div className="centerized" data-open={this.state.open+''}>
            <div className="bar1"> </div>
            <div className="bar2"> </div>
            <div className="bar3"> </div>
          </div>
        </div>
        <Container
          className= 'headerContainer'
          style={{
            position: 'fixed',
            zIndex: 200,
            width: this.props.menuOpenStatus,
          }}>
          {/*      
      <HeadButtonHome 
        className='HeadButton'
          onClick={() => { this.props.toggleTable()}}
        >
        <IoniconsIcon
          name={this.props.selectedTable?"ios-list":"ios-grid"}
          style={{
            color: "#eeee",
            fontSize: 30
          }}
        />
      </HeadButtonHome>
 
      <HeadButtonHome 
        className='HeadButton'
          onClick={() => { 
            this.props.route('Statistic')
            this.setState({selectedColor: 'Statistic'})
            }}
        >
          <MenuHomeTxt style={this.state.selectedColor=='Statistic'?{ color: '#d4e'}:{}}> Статистика</MenuHomeTxt>
        
      </HeadButtonHome>
 */}      
          <HeadButtonHome
            className='HeadButton'
            onClick={() => { 
              this.props.route('meals');
              this.setState({selectedColor: 'meals'});
            }}
          >    
            <MenuHomeTxt style={this.state.selectedColor==='meals'?{ color: '#d4e'}:{}}>блюда</MenuHomeTxt>  
          </HeadButtonHome>

          <HeadButtonHome
            className='HeadButton'
            onClick={() => { 
              this.props.route('diets');
              this.setState({selectedColor: 'diets'});
            }}
          >
            <MenuHomeTxt style={this.state.selectedColor==='diets'?{ color: '#d4e'}:{}}>диеты</MenuHomeTxt>
          </HeadButtonHome>
          <HeadButtonHome
            className='HeadButton'
            onClick={() => { 
              this.props.route('mealtime');
              this.setState({selectedColor: 'mealtime'});
            }}
          >
            <MenuHomeTxt style={this.state.selectedColor==='mealtime'?{ color: '#d4e'}:{}}>время приёма пищи</MenuHomeTxt>
       
          </HeadButtonHome>
      
          <HeadButtonHome
            className='HeadButton'
            onClick={() => { 
              this.props.route('mealsTypes');
              this.setState({selectedColor: 'mealsTypes'});
            }}
          >
            <MenuHomeTxt style={this.state.selectedColor==='mealsTypes'?{ color: '#d4e'}:{}}>категории блюд</MenuHomeTxt>
       
          </HeadButtonHome>
     
          <HeadButtonHome 
            className='HeadButton'
            onClick={() => { 
              this.props.route('rooms');
              this.setState({selectedColor: 'rooms'});
            }}
          >
            <MenuHomeTxt style={this.state.selectedColor==='rooms'?{ color: '#d4e'}:{}}>палаты</MenuHomeTxt> 
          </HeadButtonHome> 

          <HeadButtonHome 
            className='HeadButton'
            onClick={() => { 
              this.props.route('accounts');
              this.setState({selectedColor: 'accounts'});
            }}
          >
            <MenuHomeTxt style={this.state.selectedColor==='accounts'?{ color: '#d4e'}:{}}>аккаунты</MenuHomeTxt> 
          </HeadButtonHome> 

          <HeadButtonHome 
            className='HeadButton'
            onClick={() => { 
              this.props.route('history');
              this.setState({selectedColor: 'history'});
            }}
          >
            <MenuHomeTxt style={this.state.selectedColor==='history'?{ color: '#d4e'}:{}}>история</MenuHomeTxt> 
          </HeadButtonHome> 

        </Container>
      </div>
    );
  }
}
const Container = styled.div``;


const HeadButtonHome = styled.div``;

const MenuHomeTxt = styled.span`
  font-family: roboto-700;
  height: auto;
  flex: 1 1 0%;
  //color: #d4e4d4;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  transition: 1s;
`;


export default Header;
