import React, { useContext, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Button from '@material-ui/core/Button';
import {lay} from '../../../../constants/Layout';

import {Context} from '../../../../functions/context';
import BlockWrap from '../../../../styledComponents/BlockWrap';

const pageWidth = 600;
export default function PrintListTimeItem(props) {
  const context = useContext(Context); 

  let additionalMeals = [];
  let keyTimeId = 0;
  context.mealTimes.map((keyTimeItem, keyTimeIndex)=>{
    if (keyTimeItem.name === props.currentMealTime){
      keyTimeId = keyTimeItem.id;
    }
  });
  context.additionalMealsComments.map((commentObjItem, commentObjIndex)=>{
    if (commentObjItem.timeID === keyTimeId){
      additionalMeals = [...additionalMeals, commentObjItem];
    }
  });

  return(
    <BlockWrap pageWidth={pageWidth}>   
      {context.cookOutPrint 
      && context.cookOutPrint[props.currentMealTime] 
      && Object.keys(context.cookOutPrint[props.currentMealTime]).map((keyType, indType)=>{
        return(
          <BlockWrap
            key={keyType}
            pageWidth={pageWidth}
          >
            <Page>
              <h3
                style={{color: (context.theme==='light')?'#000':'#fff',}}
              >{props.currentMealTime + ' :: ' + keyType}</h3>
              <BlockWrap pageWidth={pageWidth}>
                {Object.keys(context.cookOutPrint[props.currentMealTime][keyType]).map((keyMealId, indMealId)=>{
                  if (context.mealsNumb[keyMealId]!==undefined){ 
                    return(
                      <BlockWrap
                        pageWidth={pageWidth}
                        key={indMealId}
                      >
                        <div
                          style={{
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'row',
                            width: lay.window.width-27,
                            maxWidth: pageWidth,
                            minHeight: 30,
                            backgroundColor: (context.theme==='light')?'#ddd':'#333',
                            color: (context.theme==='light')?'#000':'#fff',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderRadius: 4,
                            margin: 4,
                            padding: 4,
                          }}>
                          <span
                            style={{
                              color: (context.theme==='light')?'#000':'#fff', 
                              flex: 1, 
                              marginRight: 16,
                              minWidth: 250,
                            }}
                          >{context.mealsNumb[keyMealId].name}</span>
                          <div 
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                              flexWrap: 'wrap',
                            }}
                          >
                            {context.cookOutPrint[props.currentMealTime][keyType][keyMealId].map((itemRoom, indexRoom)=>
                              <span
                                key={indexRoom}
                                style={{
                                  color: (context.theme==='light')?'#000':'#fff',
                                  width: 50}}
                              >{itemRoom+', '}</span>
                            )}
                          </div>
                        </div>
                      </BlockWrap>
                    );
                  }else{
                    return(
                      <p
                        style={{
                          color: (context.theme==='light') ? '#822':'#f99' ,     
                          padding: 2
                        }}>
                        {`ошибка поиcка блюда № ${keyMealId}`}
                      </p>
                    );
                  }
                })}
              </BlockWrap>
            </Page>
          </BlockWrap>
        ); 
      })}
      
      {additionalMeals.map((itemMeal, indexMeal)=> {  
        return (
          <BlockWrap
            pageWidth={pageWidth}
            key={'comment'+indexMeal}
          >
            <div
              style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
                width: lay.window.width-27,
                maxWidth: pageWidth,
                minHeight: 30,
                backgroundColor: (context.theme==='light')?'#2dd':'#144',
                color: (context.theme==='light')?'#000':'#fff',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: 4,
                margin: 4,
                padding: 4,
              }}>
              <span
                style={{
                  color: (context.theme==='light')?'#000':'#fff', 
                  flex: 1, 
                  marginRight: 16,
                  minWidth: 250,
                }}
              >{itemMeal.comment}</span>
              <div 
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  flexWrap: 'wrap',
                }}
              >
                <span
                  style={{
                    color: (context.theme==='light')?'#000':'#fff',
                    width: 50}}
                >{itemMeal.room}</span>
              </div>
            </div>
          </BlockWrap>
        );
      })}

    </BlockWrap>
  );
   
}
const Page = styled.div`
  display: block;
  position: relative;
  width: ${pageWidth+16}px;
  page-break-inside: avoid !important;
  @media print {
    page-break-inside: avoid !important;
  }
`;