import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import {themes} from '../constants/Colors';

import {Context} from '../functions/context';

class LogIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: '',
    };
  }

  render(){
    return (
      <Container
        style={{ backgroundColor: themes.dark.allBackground}}
      >

        <TextInput 
          placeholder="введите пароль для входа"
          autoFocus={true}
          value={this.state.token}
          onChange={(e)=> this.setState({token: e.target.value}) }
          onKeyDown={(e)=>{
            //console.log(e)
            if (e.key === 'Enter'){
              this.props.addToken(this.state.token,'Main');
            }
          }}
          style={{
            fontSize: 26,
            color: '#ccc',
          }}
        ></TextInput>
        <div
          onClick={() => {
            this.props.addToken(this.state.token,'Main');
          }}
          className='ButtonSupport'
        >
          <span 
            className='ButtonSupportText'
          >
            Войти
          </span>
        </div>
      </Container>
    );
  }
}

LogIn.contextType = Context;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;
const TextInput = styled.input`
  font-family: Roboto-300;
  font-style: normal;
  height: 40px;
  width: 320px;
  border: none;
  background: #121212;
  margin: 5px;
  border-radius: 8px;
  padding-left: 20px;
`;


export default LogIn;