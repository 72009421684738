import React, { useState } from "react";
import  styled, { keyframes } from "styled-components";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import Top from "../../components/Top"
import TopAnswer from "../../components/TopAnswer"
import BottomShare from "../../components/BottomShare"
import InputForm from "../../components/InputForm"
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import moment from 'moment';
import 'moment/locale/ru';

import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade'
import Rotate from 'react-reveal/Rotate';
import Flip from 'react-reveal/Flip';

import Logo from '../../assets/logo'
import {lay} from '../../constants/Layout'
import { Height, InsertEmoticon } from "@material-ui/icons";


import {Context} from "../../functions/context"

import AccountForm from "../../components/AccountForm"


export default function Accounts(props) {

  //const [accounts, setAccount] = useState()


  return (
    <Context.Consumer>
    {({theme, 
      accounts,}) => (
    <>
      <Top/>
      
      <Container
      //className={classes.root}
        style={{
          paddingTop:  80,
          minHeight: lay.window.height,
          transition: '1s',
          
          backgroundColor: (theme=='light')?"#fff":"#000",
        }}
      >
          <AccountForm
            type='new'
            title='Новый пользователь'
            item={{number:0}}
            id={0}
          />
          {accounts.map((item, index)=>
            <AccountForm
              key={item.id}
              type='edit'
              title={'редактировать пользователя '+item.name}
              id={item.id}
              item={item}
            />
          )}
  
      </Container>
    </>
    )}
    </Context.Consumer>
  );
}


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  transition: '1s'
  },
  margin16: {
      margin: theme.spacing(2),
      marginBottom: theme.spacing(1),
      width: 300,
      transition: '1s',
      color: theme.palette.text.primary
  },
  button: {
   transition: '1s'
  }
  
}));

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: '1s'
`;