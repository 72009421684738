import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import {lay} from '../constants/Layout';

import {Context} from '../functions/context';
import BlockWrap from '../styledComponents/BlockWrap';
import Paragraph from '../styledComponents/Paragraph';

export default function ContraindicationsPrint(props) {
  const context = useContext(Context);

  return(
    <BlockWrap>
      <h2>противопоказания</h2>
      {context.rooms.map((item, index)=>
        item.contraindications ?
          <Paragraph theme={context.theme}>{item.number}: {item.contraindications}</Paragraph>
          : 
          null
      )}
    </BlockWrap>
  );
}