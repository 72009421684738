import React, { Component } from 'react';
import styled, { css } from 'styled-components';

import RightList from 'react-native-vector-icons/dist/Ionicons';

class MealsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      visibilityLogInBlackOut: 'hidden',
      hoverColor: '',
    };
  }


  render(){
    let w = this.props.menuOpenStatus ? 360 : 0;
    return (
      <div>
        <div className="menuMealsButton"
          onClick={()=>{
            this.props.menuOpenStatusToggle();
          }}
        >
          <div className="centerized" data-open={this.props.menuOpenStatus+''}>
            <div className="bar1"> </div>
            <div className="bar2"> </div>
            <div className="bar3"> </div>
          </div>
        </div>
        <Container
          className= 'menuMealsContainer'
          style={{
            position: 'fixed',
            zIndex: 200,
            width: this.props.menuOpenStatus ? 320 : 0,
            overflowY: 'scroll',
            overflowX: 'hidden',
            justifyContent: 'flex-start'
          }}>
          <div 
            className='HeadButton' 
            style={{marginTop:100}}
          />
          {this.props.list.map((itemList, indexList)=>
            <HeadButtonHome
              className='HeadButton'
              style={{width: 270}}
              onClick={() => { 
                this.props.route(indexList);
              }}
              key={indexList}
            >    
              <MenuHomeTxt style={(this.props.selectedMeals.indexOf(indexList)>-1)?{ color: '#d4e'}:{}}>{itemList.name}</MenuHomeTxt>  
            </HeadButtonHome>
          )}
          <div 
            className='HeadButton' 
          >
            <p></p>
          </div>
        </Container>
      </div>
    );
  }
}
const Container = styled.div``;


const HeadButtonHome = styled.div``;

const MenuHomeTxt = styled.span`
  font-family: roboto-500;
  height: auto;
  flex: 1 1 0%;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  //transition: 1s;
`;


export default MealsList;
